import React from "react";

const NotFoundPage = () => (
    <div
        style={{
            maxWidth: "200px",
            margin: "45vh auto 0 auto",
            textAlign: "center",
        }}
    >
        <h1>Oops!</h1>
        <p>This page doesn't exist yet.</p>
    </div>
);

export default NotFoundPage;
